<template>
  <div
    id="active-conferences"
    class="section-panel"
    :class="(currentMeetingList.active_conferences_count == 0) && 'noData'"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Active Conferences</h3>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="isLoading"
    >
      loading data...
    </div>

    <div
      v-if="!isLoading"
      class="section-panel-body"
    >
      <div
        v-if="currentMeetingList.active_conferences_count == 0"
        class="no-data"
      >
        There are no live conferences
      </div>
      <b-table
        v-else
        responsive
        striped
        hover
        :fields="fields"
        :items="currentMeetingList.active_conferences"
        class="activeConferencesTable"
        sticky-header
      >
        <template #cell(duration)="row">
          {{ liveTime(row.item.duration) | HoursMinsSecs }}
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';

export default {
  name: 'ActiveConferences',
  mixins: [
    ChartDataMixin,
  ],
  data() {
    return {
      fields: [
        {
          key: 'meeting_room',
          label: 'Room name',
        },
        {
          key: 'participant_count',
          label: 'No of participants',
        },
        {
          key: 'duration',
          label: 'Duration',
        },
      ],
      polling: null,
      liveTimer: null,
      seconds: 100,
    };
  },
  computed: {
    isLoading() {
      return (this.currentMeetingList.length == 0 ? true : false);
    },
    currentMeetingList() {
      return meetingStore.currentMeetings;
    },
  },
  watch: {
    currentMeetingList() {
      this.stopLiveTimer();
      if (this.currentMeetingList.active_conferences_count > 0) {
        this.startLiveTimer();
      }
    },
  },
  created() {
    this.getData();
    this.pollData(true);
  },
  beforeDestroy() {
    clearInterval(this.polling);
    clearInterval(this.liveTimer);
  },
  methods: {
    getData() {
      meetingStore.getCurrentMeetingsData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-panel {
    display: flex;
    flex-direction: column;

    .section-panel-body {
      flex: 1 0 auto;
    }

    .isLoading,
    .no-data {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
    .no-data {
      color: grey;
      height: 100%;
      padding: 0;
    }
  }
</style>
