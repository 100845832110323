<template>
  <div
    id="room-usage"
    class="section-panel"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Room Usage</h3>
        <span v-if="!isLoading">
          {{ roomUsage.from_date | dateFormat }} - {{ roomUsage.to_date | dateFormat }}
        </span>
      </div>
      <div class="section-panel-header-action">
        <router-link
          class="btn btn-link"
          :to="{name: 'room-usage'}"
        >
          Advanced View
        </router-link>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="isLoading"
    >
      loading data...
    </div>

    <div
      v-if="!isLoading"
      class="section-panel-body"
    >
      <b-table
        class="roomUsageDashboardTable"
        responsive
        striped
        sticky-header="300px"
        hover
        :items="tableData"
        :fields="fields"
      >
        <template #cell(average_duration)="row">
          {{ row.item.average_duration | HoursMinsSecs }}
        </template>
      </b-table>
    </div>
  </div>
</template>

<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';
import DateFormatMixin from '@/mixins/DateFormatMixin';

export default {
  name: 'RoomUsage',
  mixins: [
    ChartDataMixin,
    DateFormatMixin,
  ],
  data() {
    return {
      fields: [
        {
          key: 'alias',
          label: 'Room name',
          class: 'expand',
        },
        {
          key: 'conferences',
          label: 'Conferences',
          class: 'contract',
          headerAbbr: 'Number of Conferences',
        },
        {
          key: 'average_duration',
          label: 'Avg Duration',
          class: 'contract',
          headerAbbr: 'Average Duration',
        },
        {
          key: 'average_participants',
          label: 'Avg Participants',
          class: 'contract',
          headerAbbr: 'Average Participants',
        },
      ],
      polling: null,
    };
  },
  computed: {
    isLoading() {
      return (!this.roomUsage.rooms ? true : false);
    },
    tableData() {
      return this.roomUsage.rooms;
    },
    roomUsage() {
      return meetingStore.roomUsage;
    },
  },
  created() {
    this.getData();
    this.pollData(true);
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    getData() {
      meetingStore.getRoomUsageData(this.createDates(this.getLastWeek(), this.formatDateYMD()));
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
  }

  .section-panel-header-title {
    flex-wrap: wrap;
    h3 {
      margin-right: 1rem;
    }
    span {
      vertical-align: bottom;
    }
  }
</style>

<style scoped>
  .roomUsageDashboardTable {
    position: relative;
  }
  .roomUsageDashboardTable >>> table {
    border-collapse: separate;
    border-spacing: 0;
    margin-bottom: 0;
  }
  .roomUsageDashboardTable >>> .expand {
    font-weight: bold;
    width: 70%;
    white-space: nowrap;
  }
  .roomUsageDashboardTable >>> .contract {
    font-weight: normal;
    white-space: nowrap;
    width: 10%;
  }
  .roomUsageDashboardTable >>> th {
    background: white;
    position: sticky;
    top: 0px;
  }
  .roomUsageDashboardTable >>> td {
    height: 50px;
    padding: 0.85rem 1.275rem;
    vertical-align: middle;
  }
</style>
