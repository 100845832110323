<template>
  <div
    id="current-activity"
    class="section-panel"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Current Activity</h3>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="isLoading"
    >
      loading data...
    </div>

    <div
      v-if="!isLoading"
      class="section-panel-body"
    >
      <b-card>
        <b-card-title>
          {{ currentMeetings.active_conferences_count }}
        </b-card-title>
        <b-card-text>
          Active conferences
        </b-card-text>
      </b-card>

      <b-card>
        <b-card-title>
          {{ currentMeetings.active_participants_count }}
        </b-card-title>
        <b-card-text>
          Total Participants
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';

export default {
  name: 'CurrentActivity',
  mixins: [
    ChartDataMixin,
  ],
  data() {
    return {
      polling: null,
    };
  },
  computed: {
    isLoading() {
      return (this.currentMeetings.length == 0 ? true : false);
    },
    currentMeetings() {
      return this.currentMeetingList;
    },
    currentMeetingList() {
      return meetingStore.currentMeetings;
    },
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    getData() {
      meetingStore.getCurrentMeetingsData();
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
    .section-panel-body {
      display: flex;
      > div {
        flex: 1 1 50%;

        &:first-child {
          border-radius: 0.25rem 0 0 0.25rem;
        }
        &:last-child {
          border-radius: 0 0.25rem 0.25rem 0;
          border-left: 0;
        }
      }
    }
  }
</style>
