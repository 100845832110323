<template>
  <main
    id="site-wrapper"
  >
    <section class="section">
      <div class="container-fluid">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{name: 'admin'}">
              Admin
            </router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ componentTitle }}
          </li>
        </ol>
        <div class="section-header">
          <div class="section-header-title">
            <router-link
              class="section-header-back"
              :to="{name: 'dashboard'}"
            >
              <i class="fas fa-arrow-circle-left" />
            </router-link>
            <h1>
              {{ componentTitle }}
            </h1>
          </div>
        </div>

        <div class="dash-grid">
          <current-activity id="currentActivity" />
          <recent-calls id="recentCalls" />
          <endpoints id="endpoints" />
          <meetings-by-date id="meetingsByDate" />
          <room-usage id="roomUsage" />
          <active-conferences id="activeConferences" />
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import CurrentActivity from './components/CurrentActivity.vue';
import ActiveConferences from './components/ActiveConferences.vue';
import MeetingsByDate from './components/MeetingsByDate.vue';
import Endpoints from './components/Endpoints.vue';
import RoomUsage from './components/RoomUsage.vue';
import RecentCalls from './components/RecentCalls.vue';

export default {
  name: 'OperationalDashboard',
  components: {
    CurrentActivity,
    ActiveConferences,
    MeetingsByDate,
    Endpoints,
    RoomUsage,
    RecentCalls,
  },
  created() {
    // Constants
    this.componentTitle = 'Operational Dashboard';
  },
};
</script>

<style lang="scss" scoped>
.dash-grid {
  display: flex;
  flex-direction: column;

  & > * {
    margin-top: 2rem;
  }

  & #currentActivity {
    order: 1;
  }

  & #activeConferences {
    order: 2;
  }

  & #recentCalls {
    order: 3;
  }

  & #endpoints {
    order: 4;
  }

  & #roomUsage {
    order: 5;
  }

  & #meetingsByDate {
    order: 6;
  }
}

@media (min-width: 992px) {
  .dash-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0,1fr);
    row-gap: 2rem;
    column-gap: 2rem;

    & > * {
      margin-top: 0;
    }

    & #currentActivity {
      grid-row: 1;
      grid-column: 1;
    }

    & #recentCalls {
      grid-row: 1 / 3;
      grid-column: 2 / 2;
    }

    & #activeConferences {
      grid-row: 2 / 3;
      grid-column: 1 ;
    }

    & #endpoints {
      grid-row: 3;
      grid-column: 1 ;
    }

    & #roomUsage {
      grid-row: 3;
      grid-column: 2;
    }

    & #meetingsByDate {
      grid-column: 1 / 3;
    }
  }
}

@media (min-width: 1200px) {
  .dash-grid {
    display: grid;
    grid-template-columns: minmax(0, 2fr) minmax(0, 3fr);
  }
}

@media (min-width: 1400px) {
  .dash-grid {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr);
    max-width: 1400px;

    & #currentActivity {
      grid-row: 1;
      grid-column: 1;
    }

    & #recentCalls {
      grid-row: 1 / 3;
      grid-column: 3;
    }

    & #activeConferences {
      grid-row: 1;
      grid-column: 2;
    }

    & #endpoints {
      grid-row: 3;
      grid-column: 3 ;
    }

    & #roomUsage {
      grid-row: 2;
      grid-column: 1 / 3;
    }

    & #meetingsByDate {
      grid-column: 1 / 3;
    }
  }
}
</style>
