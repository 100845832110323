<template>
  <div
    id="endpoints"
    class="section-panel"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Endpoints</h3>
      </div>
      <div
        v-if="chartEndpoints && chartEndpoints.length > 0"
        class="section-panel-header-action"
      >
        <router-link
          class="btn btn-link"
          :to="{name: 'endpoints'}"
        >
          Advanced View
        </router-link>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="isLoading"
    >
      loading data...
    </div>

    <div
      v-if="!isLoading"
      class="section-panel-body"
    >
      <div
        v-if="chartEndpoints && chartEndpoints.length > 0"
        id="historic-chart-div"
        class="historic"
      >
        <pie-chart
          :custom-labels="getLabels(chartEndpoints)"
          :custom-b-g-colors="generateColourArray(chartEndpoints.length)"
          :custom-data="getPropValues(chartEndpoints, 'percentage')"
          :custom-count="getPropValues(chartEndpoints, 'participants')"
          :styles="pieChartStyles"
        />
      </div>
      <div
        v-else
        class="no-data"
      >
        There isn't any endpoint data available yet
      </div>
    </div>
  </div>
</template>
<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';
import PieChart from './charts/EndpointsPie.js';

export default {
  name: 'Endpoints',
  components: {
    PieChart,
  },
  mixins: [
    ChartDataMixin,
  ],
  data() {
    return {
      chartWidth: 100,
      polling: null,
    };
  },
  computed: {
    isLoading() {
      return (!this.historicEndpoints.endpoints ? true : false);
    },
    chartEndpoints() {
      return this.historicEndpoints.endpoints;
    },
    historicEndpoints() {
      return meetingStore.historicEndpointsCharts;
    },
    pieChartStyles() {
      return {
        height: '#{this.chartWidth}px',
        width: '#{this.chartWidth}px',
        position: 'relative',
      };
    },
  },
  created() {
    this.getData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  mounted() {
    // this.chartWidth = this.chartContainerWidth();
  },
  methods: {
    getData() {
      meetingStore.getHistoricEndpointsCharts();
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-panel {
    display: flex;
    flex-direction: column;

    .section-panel-body {
      flex: 1 0 auto;
    }

    .isLoading,
    .no-data {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
    .no-data {
      color: grey;
      height: 100%;
      padding: 0;
    }
  }
</style>
