<template>
  <div
    id="meetings-by-date"
    class="section-panel"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Meetings by date</h3>
        <span v-if="!isLoading && currentMeetingList.from_date">
          {{ currentMeetingList.from_date | dateFormat }} - {{ currentMeetingList.to_date | dateFormat }}
        </span>
      </div>
      <div class="section-panel-header-action">
        <router-link
          class="btn btn-link"
          :to="{name: 'meetings-by-date'}"
        >
          Advanced View
        </router-link>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="isLoading"
    >
      loading data...
    </div>

    <div
      v-if="!isLoading"
      class="section-panel-body"
    >
      <b-table
        class="meetingsByDateTable"
        responsive
        striped
        hover
        :items="tableDataSet"
        :fields="tableDataFields"
      >
        <template #cell(total_duration)="row">
          {{ row.item.total_duration | HoursMinsSecs }}
        </template>

        <template #cell(average_duration)="row">
          {{ row.item.average_duration | HoursMinsSecs }}
        </template>
      </b-table>
    </div>
  </div>
</template>
<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';
import DateFormatMixin from '@/mixins/DateFormatMixin';

export default {
  name: 'MeetingsByDate',
  mixins: [
    ChartDataMixin,
    DateFormatMixin,
  ],
  data() {
    return {
      polling: null,
      tableDataFields: [
        {
          key: 'date',
          label: 'Date',
          tdClass: 'no-wrap',
        },
        {
          key: 'conferences',
          label: 'Conferences',
        },
        {
          key: 'total_participants',
          label: 'Total Participants',
        },
        {
          key: 'average_participants',
          label: 'Avg Participants',
        },
        {
          key: 'total_duration',
          label: 'Total time',
        },
        {
          key: 'average_duration',
          label: 'Avg time',
        },
        {
          key: 'rooms_count',
          label: 'Rooms Count',
        },
      ],
    };
  },
  computed: {
    isLoading() {
      return (!this.currentMeetingList.conferences_by_dates ? true : false);
    },
    tableDataSet() {
      return this.currentMeetingList.conferences_by_dates;
    },
    currentMeetingList() {
      return meetingStore.meetingsByDateData;
    },
  },
  created() {
    this.getData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    getData() {
      meetingStore.getMeetingsByDateData(this.createDates(this.getLastWeek(), this.formatDateYMD()));
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }
  }

  .section-panel-header-title {
    flex-wrap: wrap;
    h3 {
      margin-right: 1rem;
    }
    span {
      vertical-align: bottom;
    }
  }
</style>

<style scoped>
  .meetingsByDateTable >>> .no-wrap {
    white-space: nowrap;
    padding: 1.275rem;
  }

  .meetingsByDateTable >>> td {
    vertical-align: middle;
  }
</style>
