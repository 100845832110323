<template>
  <div
    id="recent-calls"
    class="section-panel"
  >
    <div class="section-panel-header">
      <div class="section-panel-header-title">
        <h3>Recent call quality</h3>
      </div>
    </div>

    <div
      v-if="isLoading"
      class="isLoading"
    >
      loading data...
    </div>

    <div
      v-if="!isLoading"
      class="section-panel-body"
    >
      <h4>Last 200 calls</h4>
      <dl>
        <template v-for="item in widgetData(RecentCalls.last_200_calls_quality)">
          <dt :key="item.quality + '-L'">
            {{ item.quality }}
          </dt>
          <dd
            :key="item.quality + '-L2'"
            :class="item.quality"
          >
            {{ item.participants }}
          </dd>
        </template>
      </dl>
      <h4>Total Participants</h4>
      <dl>
        <template v-for="item in widgetData(RecentCalls.participants_of_last_200_calls_quality)">
          <dt :key="item.quality + '-P'">
            {{ item.quality }}
          </dt>
          <dd
            :key="item.quality + '-P2'"
            :class="item.quality"
          >
            {{ item.participants }}
          </dd>
        </template>
      </dl>
      <h4>Media Streams</h4>
      <dl>
        <template v-for="item in widgetData(RecentCalls.media_streams_of_last_200_calls_quality)">
          <dt :key="item.quality + '-M'">
            {{ item.quality }}
          </dt>
          <dd
            :key="item.quality + '-M2'"
            :class="item.quality"
          >
            {{ item.participants }}
          </dd>
        </template>
      </dl>
    </div>
  </div>
</template>
<script>
import {meetingStore} from '@/store/__STORE_meetings';
import ChartDataMixin from '@/mixins/ChartDataMixin';

export default {
  name: 'RecentCalls',
  mixins: [
    ChartDataMixin,
  ],
  data() {
    return {
      labels: {
        good: 'good',
        average: 'average',
        poor: 'poor',
      },
      polling: null,
    };
  },
  computed: {
    isLoading() {
      return (!this.recentCalls.last_200_calls_quality ? true : false);
    },
    RecentCalls() {
      return this.recentCalls;
    },
    recentCalls() {
      return meetingStore.recentCallsCharts;
    },
  },
  created() {
    this.getData();
    this.pollData();
  },
  beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    getData() {
      meetingStore.getRecentCallsChartsData();
    },
    widgetData(tableData) {
      if (tableData) {
        const formattedData = [];
        formattedData[0] = {
          'quality': this.labels.good,
          'participants': tableData.good_count,
        };
        formattedData[1] = {
          'quality': this.labels.average,
          'participants': tableData.average_count,
        };
        formattedData[2] = {
          'quality': this.labels.poor,
          'participants': tableData.poor_count,
        };
        return formattedData;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .section-panel {
    display: flex;
    flex-direction: column;

    .isLoading {
      align-items: center;
      display: flex;
      flex: 1 0 auto;
      justify-content: center;
      padding: 2rem;
    }

    .section-panel-body {
      h4 {
        font-size: 1.7rem;
      }
      dl {
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        &:last-child {
          margin-bottom: 0;
        }

        dt {
          flex: 1 1 33%;
          font-weight: normal;
          order: 1;
          text-transform: capitalize;
          padding: 0.5rem;
        }
        dd {
          border-top: 2px solid white;
          flex: 1 1 33%;
          font-size: 3rem;
          margin-bottom: 0;
          order: 2;
          padding: 0.5rem;

          &.good {
            border-color: green;
          }
          &.average {
            border-color: yellow;
          }
          &.poor {
            border-color: red;
          }
        }
      }
    }
  }
</style>
